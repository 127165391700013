<template>
  <div style="background-color: #FFFFFF;">
    <el-row>
      <el-col :span="24">
        <div class="content-list">
          <!-- 选项卡 -->
          <div style="position: relative;">
            <el-tabs v-model="activeName" @tab-click="handleClick">
              <el-tab-pane v-for="(item, index) of navList" :key="index" :name="item.active">
                <template #label>
                  <span class="font-title-tabs">{{item.name}}<span style="margin-left: 10px;">{{item.num}}</span></span>
                </template>
              </el-tab-pane>
            </el-tabs>
            <span class="nav-message">审核流程，普通会员在7-10个工作日内完成校验,VIP会员在1-2个工作日内完成校验</span>
          </div>
          <!-- 分类 -->
          <div class="dis-bet-cen" style="margin-bottom: 10px">
            <span class="btn-type" @click="handleChooseType(index)" :class="{active:index === btnActive}" v-for="(item, index) of typeList" :key="index">
              <span>{{item.name}}</span>
              <span style="margin-left: 5px;" v-if="activeName == 0">{{item.toBeVerifiedMap}}</span>
              <span style="margin-left: 5px;" v-else-if="activeName == 1">{{item.passMap}}</span>
              <span style="margin-left: 5px;" v-else-if="activeName == 2">{{item.noPassMap}}</span>
            </span>
          </div>
          <!-- 时间 -->
          <div class="dis-bet-cen" style="margin-bottom: 10px">
            <el-date-picker
              v-model="valueTime"
              @change="handleTimeChange"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYY-MM-DD"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
            </el-date-picker>
            <el-select v-model="honorValue" @change="handleHonorSelect" placeholder="请选择" v-if="btnActive === 4">
              <el-option
                v-for="item in honorOption"
                :key="item.name"
                :label="item.name"
                :value="item.name">
                <span>{{ item.name }}</span>
              </el-option>
            </el-select>
          </div>
          <!-- 表格 -->
          <div >
            <el-table
              border
              :data="tableData"
              v-loading="loading"
              element-loading-background="rgba(255, 255, 255, 1)"
              :cell-style="cellStyle"
              :header-cell-style="headerColor"
              style="width: 100%;">
              <el-table-column type="index" width="50" label="序号"></el-table-column>
              <el-table-column v-for="(item,index) in tableHeaderData" :key="index" :label="item.label" :property="item.property" align="center">
                <template #default="scope">
                  <div v-if="scope.column.property === 'handleImg'">
                    <span v-if="scope.row['imgUrl'].length > 0" @click="show(scope.row.imgUrl)" class="btn-hover-foucs">查看图片<br></span>
                    <span v-if="scope.row['pdfUrl'].length > 0"  >
                      <el-popover
                        placement="left"
                        width="200"
                        trigger="hover">
                        <template #reference>
                          <span>查看PDF</span>
                        </template>
                        <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in scope.row['pdfUrl']" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                      </el-popover>
                    </span>
                    <span v-if="scope.row['imgUrl'].length === 0 && scope.row['pdfUrl'].length === 0">-</span>
                  </div>
                  <span @click="showDetile(scope.row)" v-else-if="scope.column.property === 'handleDetails'" class="btn-hover-foucs">详情</span>
                  <span v-else>{{scope.row[scope.column.property] || '-'}}</span>
                </template>
              </el-table-column>
              <template #empty style="width: 100%;">
                <el-empty :image-size="200"></el-empty>
              </template>
            </el-table>
            <!--分页组件-->
            <div class="dis-end-sta" style="margin-bottom: 30px">
              <el-pagination
                :hide-on-single-page="true"
                :pager-count="7"
                background
                layout="prev, pager, next"
                v-model:currentPage="page.pageNum"
                @current-change="currentChange"
                :total="page.total">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-dialog :title="getDialogTitle()" append-to-body="true" v-model="dialogTableVisible">
      <!-- 资质证书 -->
      <block v-if="btnActive === 0">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>资质名称</template>
            <div style="width: 150px;">{{companyDetail.credentialName || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>证书编号</template>
            <div style="width: 150px;">{{companyDetail.credentialNumber || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发证机构</template>
            <div style="width: 150px;">{{companyDetail.issuedBy || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>有效日期</template>
            <div style="width: 150px;">{{companyDetail.issueDates || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover2"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover2>查看PDF</span>
              </span>
              <span v-if="companyDetail.imgUrl.length === 0 && companyDetail.pdfUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>提交时间</template>
            <div style="width: 150px;">{{companyDetail.createTime || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('credential')">重新提交</el-button>
        </div>
      </block>
      <!-- 荣誉证书 -->
      <block v-if="btnActive === 1">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>荣誉名称</template>
            <div style="width: 150px;">{{companyDetail.name || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>荣誉类别</template>
            <div style="width: 150px;">{{companyDetail.honorType || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发证日期</template>
            <div style="width: 150px;">{{companyDetail.issueData || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发证机构</template>
            <div style="width: 150px;">{{companyDetail.issuedBy || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片3' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover3"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover3>查看PDF</span>
              </span>
              <span v-if="companyDetail.imgUrl.length === 0 && companyDetail.pdfUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>提交时间</template>
            <div style="width: 150px;">{{companyDetail.createTime || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('honorImage')">重新提交</el-button>
        </div>
      </block>
      <!-- 技术人员-->
      <block v-if="btnActive === 2">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>人员名称</template>
            <div style="width: 150px;">{{companyDetail.name || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>人员分类</template>
            <div style="width: 150px;">{{companyDetail.peopleType || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>岗位类别</template>
            <div style="width: 150px;">{{companyDetail.registeredType  || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>执业印章号</template>
            <div style="width: 150px;">{{companyDetail.certificateno || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="companyDetail.peopleType == '注册建造师' || companyDetail.peopleType == '注册建筑师'|| companyDetail.peopleType == '注册造价工程师'||companyDetail.peopleType == '注册监理工程师'">
            <template #label>注册专业</template>
            <div style="width: 150px;">{{companyDetail.registeredProfessional || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="companyDetail.peopleType == '其他人员'">
            <template #label>原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片4' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover4"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover4>查看PDF</span>
              </span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="companyDetail.peopleType == '注册公用设备工程师' || companyDetail.peopleType == '注册结构工程师' ||companyDetail.peopleType == '注册土木工程师（岩土）'||companyDetail.peopleType == '注册化工工程师' ||companyDetail.peopleType == '注册监理工程师'">
            <template #label>有效时间</template>
            <div style="width: 150px;">{{companyDetail.registeredTimes || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>提交时间</template>
            <div style="width: 150px;">{{companyDetail.createTime || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('registeredPersonnel')">重新提交</el-button>
        </div>
      </block>
      <!-- 项目业绩 -->
      <block v-if="btnActive === 3 && companyDetail.isSubcontract === '总包'">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>项目名称</template>
            <div style="width: 150px;">{{companyDetail.projectName || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>项目承包</template>
            <div style="width: 150px;">{{companyDetail.isSubcontract || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>合同总额</template>
            <div style="width: 150px;">{{companyDetail.money || '-'}}元</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>建设单位</template>
            <div style="width: 150px;">{{companyDetail.projectConstructor || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>中标日期</template>
            <div style="width: 150px;">{{companyDetail.timeSuccess || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>中标链接</template>
            <div style="width: 150px;">{{companyDetail.url || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>合同原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="show(companyDetail.imgUrl)">{{companyDetail.imgUrl ? '查看图片5' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover5"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover5>查看PDF</span>
              </span>
              <span v-if="companyDetail.imgUrl.length === 0 && companyDetail.pdfUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发票原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.invoiceImgUrl.length > 0" @click="companyDetail.invoiceImgUrl ? show(companyDetail.invoiceImgUrl) : ''">{{companyDetail.invoiceImgUrl ? '查看图片6' : ''}}<br></span>
              <span v-if="companyDetail.invoicePdfUrl.length > 0"  >
                <el-popover
                  ref="popover6"
                  placement="left"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.invoicePdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover6>查看PDF</span>
              </span>
              <span v-if="companyDetail.invoiceImgUrl.length === 0 && companyDetail.invoicePdfUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('achievement')">重新提交</el-button>
        </div>
      </block>
      <!-- 项目业绩分包 -->
      <block v-if="btnActive === 3 && companyDetail.isSubcontract === '分包'">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="2">
            <template #label>项目名称</template>
            <div style="width: 150px;">{{companyDetail.projectName || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>项目承包</template>
            <div style="width: 150px;">{{companyDetail.isSubcontract || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>合同总额</template>
            <div style="width: 150px;">{{companyDetail.money || '-'}}元</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>建设单位</template>
            <div style="width: 150px;">{{companyDetail.projectConstructor || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>中标日期</template>
            <div style="width: 150px;">{{companyDetail.timeSuccess || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>分包合同原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.partContractImgUrl.length > 0" @click="companyDetail.partContractImgUrl ? show(companyDetail.partContractImgUrl) : ''">{{companyDetail.partContractImgUrl ? '查看图片7' : ''}}<br></span>
              <span v-if="companyDetail.partContractPdfUrl.length > 0"  >
                <el-popover
                  ref="popover7"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.partContractPdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover7>查看PDF</span>
              </span>
              <span v-if="companyDetail.partContractImgUrl.length === 0 && companyDetail.partContractPdfUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发票原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.invoiceImgUrl.length > 0" @click="companyDetail.invoiceImgUrl ? show(companyDetail.invoiceImgUrl) : ''">{{companyDetail.invoiceImgUrl ? '查看图片8' : ''}}<br></span>
              <span v-if="companyDetail.invoicePdfUrl.length > 0"  >
                <el-popover
                  ref="popover8"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.invoicePdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover8>查看PDF</span>
              </span>
              <span v-if="companyDetail.invoicePdfUrl.length === 0 && companyDetail.invoiceImgUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>中标链接</template>
            <div style="width: 150px;">{{companyDetail.url || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>总包合同原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片9' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover9"
                  placement="right"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover9>查看PDF</span>
              </span>
              <span v-if="companyDetail.pdfUrl.length === 0 && companyDetail.imgUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('achievement')">重新提交</el-button>
        </div>
      </block>
      <!-- 知识产权软著 -->
      <block v-if="btnActive === 4 && typeList[4].status === '16'">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>软件名称</template>
            <div style="width: 150px;">{{companyDetail.name || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>简称</template>
            <div style="width: 150px;">{{companyDetail.shortName || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>版本号</template>
            <div style="width: 150px;">{{companyDetail.versionNo || '-'}}元</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>登记号</template>
            <div style="width: 150px;">{{companyDetail.registerNo || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发布日期</template>
            <div style="width: 150px;">{{companyDetail.publishDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>登记批准日期</template>
            <div style="width: 150px;">{{companyDetail.registerAperDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>分类号</template>
            <div style="width: 150px;">{{companyDetail.category || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片10' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover10"
                  placement="left"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover10>查看PDF</span>
              </span>
              <span v-if="companyDetail.pdfUrl.length === 0 && companyDetail.imgUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('intellectualProperty')">重新提交</el-button>
        </div>
      </block>
      <!-- 知识产权专利 -->
      <block v-if="btnActive === 4 && typeList[4].status === '15'">
        <el-descriptions class="margin-top"  :column="2" :size="size" border>
          <el-descriptions-item :span="1">
            <template #label>专利名称</template>
            <div style="width: 150px;">{{companyDetail.title || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>产权分类</template>
            <div style="width: 150px;">{{'专利信息' || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>证书编号</template>
            <div style="width: 150px;">{{companyDetail.certificateNumber || '-'}}元</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>专利类别</template>
            <div style="width: 150px;">{{companyDetail.kindcodedesc || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>发证日期</template>
            <div style="width: 150px;">{{companyDetail.releaseDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1">
            <template #label>原件扫描件</template>
            <div class="btn-hover-foucs">
              <span v-if="companyDetail.imgUrl.length > 0" @click="companyDetail.imgUrl ? show(companyDetail.imgUrl) : ''">{{companyDetail.imgUrl ? '查看图片11' : ''}}<br></span>
              <span v-if="companyDetail.pdfUrl.length > 0"  >
                <el-popover
                  ref="popover11"
                  width="200"
                  trigger="hover">
                  <div style="margin: 10px 0px;color: #666666" class="btn-hover-foucs" v-for="(item,index) in companyDetail.pdfUrl" :key="index" @click="showPdf(item)">{{ item.split('/')[item.split('/').length - 1] }}</div>
                </el-popover>
                <span v-popover:popover11>查看PDF</span>
              </span>
              <span v-if="companyDetail.pdfUrl.length === 0 && companyDetail.imgUrl.length === 0">-</span>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批时间</template>
            <div style="width: 150px;">{{companyDetail.auditDate || '-'}}</div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" v-if="activeName != 0">
            <template #label>审批意见</template>
            <div style="width: 150px;">{{companyDetail.reason || '-'}}</div>
          </el-descriptions-item>
        </el-descriptions>
        <div style="text-align: right;margin-top: 10px;" v-if="activeName === 2">
          <el-button type="primary" size="small" @click="handleResubmit('intellectualProperty')">重新提交</el-button>
        </div>
      </block>
    </el-dialog>
  </div>
</template>

<script>
import { getAllArtisanRelevancy } from '@/api/query.js'
import { selectCompanyCreditEnhancement, selectCompanyCreditEnhancementCount } from '@/api/forward.js'
import { mapGetters } from 'vuex'
export default {
  name: 'data-maintain-person',
  data () {
    return {
      images: [
        'https://picsum.photos/200/200',
        'https://picsum.photos/300/200',
        'https://picsum.photos/250/200'
      ],
      loading: false,
      dialogTableVisible: false,
      companyDetail: {},
      btnActive: 0,
      activeName: '0',
      valueTime: '',
      page: {
        pageNum: 1,
        total: 0
      },
      honorValue: '专利信息',
      honorOption: [
        {
          name: '专利信息'
        },
        {
          name: '软件著作权'
        }
      ],
      navList: [
        {
          name: '待核实',
          num: 0,
          active: 0,
          value: 'toBeVerified'
        },
        {
          name: '通过',
          num: 0,
          active: 1,
          value: 'pass'
        },
        {
          name: '不通过',
          num: 0,
          active: 2,
          value: 'noPass'
        }
      ],
      typeList: [
        {
          name: '资质证书',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 0,
          status: '10',
          value: 'tXinyongCompanyCredential'
        },
        {
          name: '荣誉证书',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 1,
          status: '11',
          value: 'tXinyongCompanyHonorImage'
        },
        {
          name: '技术人员',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 2,
          status: '12',
          value: 'tXinyongCompanyRegisteredPersonnel'
        },
        {
          name: '项目业绩',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 3,
          status: '13',
          value: 'tXinyongCompanyAchievement'
        },
        {
          name: '知识产权',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 4,
          status: '15',
          value: 'tXinyongPatents'
        },
        {
          name: '税务荣誉',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 5,
          status: '14',
          value: 'tXinyongTaxcredit'
        },
        {
          name: '其他',
          passMap: 0,
          noPassMap: 0,
          toBeVerifiedMap: 0,
          active: 6,
          status: '20',
          value: 'tXinyongOther'
        }
      ],
      tableHeaderData: [],
      tableData: [],
      totalCascaderList: [] // 所有人员分类
    }
  },
  computed: {
    ...mapGetters([
      'vipData', // 会员数据
      'idToken',
      'userInfo'
    ])
  },
  created () {
    this.initArrangementRadioValueArr()
  },
  mounted () {
    if (this.userInfo) {
      this.userInfoMes = JSON.parse(this.userInfo)
      if (this.userInfoMes.businessInformationStatus === '1') {
        this.loading = true
        this.getDataNum()
        this.getData()
      } else {
        this.loading = true
        this.getDataNum()
        this.getData()
      }
    }
  },
  methods: {
    // 表格设置样式
    headerColor: function ({ row, column, rowIndex, columnIndex }) {
      return 'background-color:#E8EBFC;color:#666666;font-size:14px;text-align:center'
    },
    cellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return 'text-align:center'
    },
    // 图片预览
    show (e) {
      if (e.indexOf(',') !== -1) {
        const a = e.split(',')
        if (a.length > 0 && a[0]) {
          this.$viewerApi({
            images: a
          })
        }
      } else {
        if (e) {
          this.$viewerApi({
            images: e
          })
        }
      }
    },
    // 打开pdf
    showPdf (e) {
      window.open(e, '_black')
    },
    // 重新提交
    handleResubmit (obj) {
      const vm = this
      const model = {
        key: obj,
        value: vm.companyDetail
      }
      vm.$store.dispatch('setDataMaintainModel', JSON.stringify(model))
      vm.$router.push({ name: 'data-maintain' })
    },
    // 查看详情
    showDetile (e) {
      this.companyDetail = {}
      this.companyDetail = e
      this.dialogTableVisible = true
    },
    // 切换知识产权
    handleHonorSelect (e) {
      const vm = this
      vm.loading = true
      this.tableHeaderData = []
      if (e === '专利信息') {
        vm.typeList[4].status = '15'
      } else {
        vm.typeList[4].status = '16'
      }
      vm.page.pageNum = 1
      vm.page.total = 0
      vm.getData()
    },
    handleClick (tab, event) {
      this.loading = true
      this.tableHeaderData = []
      this.getData()
    },
    handleChooseType (e) {
      this.loading = true
      this.btnActive = e
      this.tableHeaderData = []
      this.page.pageNum = 1
      this.page.total = 0
      this.getData()
    },
    // 切换时间
    handleTimeChange () {
      this.loading = true
      this.page.pageNum = 1
      this.page.total = 0
      this.getData()
    },
    // 获取数量
    getDataNum () {
      const vm = this
      const data = {
        // dateBackgroundUrl: '/CompanyCreditEnhancement/selectCompanyCreditEnhancementCount',
        // dateBackgroundRequestType: 'get',
        // data: {
        //   userId: vm.userInfoMes.userId
        // },
        userId: vm.userInfoMes.userId
      }
      selectCompanyCreditEnhancementCount(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          const arr = []
          vm.navList.forEach(item => {
            for (const i in res.maintenanceRecordMap) {
              if (item.value === i) {
                item.num = res.maintenanceRecordMap[i]
              }
            }
            arr.push(item)
          })
          vm.navList = arr
          const arrs = []
          vm.typeList.forEach(item => {
            for (const i in res.noPassMap) {
              if (item.value === 'tXinyongPatents') {
                item.noPassMap = Number(res.noPassMap.tXinyongPatent) + Number(res.noPassMap.tXinyongCopyrightsSoftwarecopyright)
              } else if (item.value === i) {
                item.noPassMap = res.noPassMap[i]
              }
            }
            for (const i in res.passMap) {
              if (item.value === 'tXinyongPatents') {
                item.passMap = Number(res.passMap.tXinyongPatent) + Number(res.passMap.tXinyongCopyrightsSoftwarecopyright)
              } else if (item.value === i) {
                item.passMap = res.passMap[i]
              }
            }
            for (const i in res.toBeVerifiedMap) {
              if (item.value === 'tXinyongPatents') {
                item.toBeVerifiedMap = Number(res.toBeVerifiedMap.tXinyongPatent) + Number(res.toBeVerifiedMap.tXinyongCopyrightsSoftwarecopyright)
              } else if (item.value === i) {
                item.toBeVerifiedMap = res.toBeVerifiedMap[i]
              }
            }
            arrs.push(item)
          })
          vm.typeList = arrs
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取所有人员分类
    initArrangementRadioValueArr () {
      const vm = this
      getAllArtisanRelevancy().then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.totalCascaderList = res
        }
      }).catch(error => {
        console.log(error)
      })
    },
    // 获取数据list
    getData () {
      const vm = this
      const data = {
        // dateBackgroundUrl: '/CompanyCreditEnhancement/selectCompanyCreditEnhancement',
        // dateBackgroundRequestType: 'get',
        // data: {
        //   userId: vm.userInfoMes.userId,
        //   companyName: vm.userInfoMes.companyName,
        //   type: vm.typeList[vm.btnActive].status,
        //   auditStatus: vm.activeName,
        //   startDate: vm.valueTime ? vm.valueTime[0] + ' 00:00:00' : '',
        //   endDate: vm.valueTime ? vm.valueTime[1] + ' 23:59:59' : '',
        //   pageNum: vm.page.pageNum,
        //   pageSize: 10
        // },
        userId: vm.userInfoMes.userId,
        companyName: vm.userInfoMes.companyName,
        type: vm.typeList[vm.btnActive].status,
        auditStatus: vm.activeName,
        startDate: vm.valueTime ? vm.valueTime[0] + ' 00:00:00' : '',
        endDate: vm.valueTime ? vm.valueTime[1] + ' 23:59:59' : '',
        pageNum: vm.page.pageNum,
        pageSize: 10
      }
      selectCompanyCreditEnhancement(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          vm.page.total = res.total ? res.total : 0
          const arr = []
          vm.tableData = []
          // 资质
          if (vm.btnActive === 0 || vm.btnActive === '0') {
            if (vm.activeName === '0' || vm.activeName === 0) {
              this.tableHeaderData = [{
                label: '资质名称',
                property: 'credentialName'
              }, {
                label: '证书编号',
                property: 'credentialNumber'
              }, {
                label: '发证机构',
                property: 'issuedBy'
              }, {
                label: '有效日期',
                property: 'issueDates'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '提交时间',
                property: 'createTime'
              }]
            } else {
              this.tableHeaderData = [{
                label: '资质名称',
                property: 'credentialName'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }, {
                label: '操作',
                property: 'handleDetails'
              }]
            }
            res.list.forEach(item => {
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                  console.log(item.pdfUrl)
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              item.issueDates = item.issueDate ? (item.issueDate.substring(0, 10) + '至' + item.closingDate.substring(0, 10)) : '-'
              arr.push(item)
            })
            console.log(arr)
            vm.tableData = arr
          } else if (vm.btnActive === 1) {
            if (vm.activeName === '0') {
              this.tableHeaderData = [{
                label: '荣誉名称',
                property: 'name'
              }, {
                label: '荣誉类别',
                property: 'honorType'
              }, {
                label: '发证日期',
                property: 'issueData'
              }, {
                label: '发证机构',
                property: 'issuedBy'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '提交时间',
                property: 'createTime'
              }]
            } else {
              this.tableHeaderData = [{
                label: '荣誉名称',
                property: 'name'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }, {
                label: '操作',
                property: 'handleDetails'
              }]
            }
            res.list.forEach(item => {
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              item.issueData = item.issueData ? item.issueData : '-'
              if (item.honorType === 1) {
                item.honorType = '行业荣誉'
              } else if (item.honorType === 2) {
                item.honorType = '工商荣誉'
              }
              arr.push(item)
            })
            vm.tableData = arr
            // 技术人员
          } else if (vm.btnActive === 2) {
            if (vm.activeName === '0') {
              this.tableHeaderData = [{
                label: '人员名称',
                property: 'name'
              }, {
                label: '人员分类',
                property: 'registeredType'
              }, {
                label: '执业印章号',
                property: 'certificateno'
              }, {
                label: '有效日期',
                property: 'registeredTimes'
              }, {
                label: '附件',
                property: 'handleImg'
              }, {
                label: '提交时间',
                property: 'createTime'
              }]
            } else {
              this.tableHeaderData = [{
                label: '人员名称',
                property: 'name'
              }, {
                label: '人员分类',
                property: 'registeredType'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '附件',
                property: 'handleImg'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }, {
                label: '操作',
                property: 'handleDetails'
              }]
            }
            res.list.forEach(item => {
              vm.totalCascaderList.forEach(items => {
                if (item.registeredType === items.name && items.parentId === '0') {
                  item.peopleType = items.name
                } else if (item.registeredType === items.name && items.parentId !== '0') {
                  for (let i = 0; i < vm.totalCascaderList.length; i++) {
                    if (vm.totalCascaderList[i].parentId === '0' && vm.totalCascaderList[i].id === items.parentId) {
                      item.peopleType = vm.totalCascaderList[i].name
                    }
                  }
                } else if (item.level === '其他人员') {
                  item.peopleType = '其他人员'
                }
              })
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              item.registeredTimes = item.registeredTime ? (item.registeredTime.substring(0, 10) + '至' + item.validPeriod.substring(0, 10)) : '-'
              arr.push(item)
            })
            vm.tableData = arr
            // 项目业绩
          } else if (vm.btnActive === 3) {
            if (vm.activeName === '0') {
              this.tableHeaderData = [{
                label: '项目名称',
                property: 'projectName'
              }, {
                label: '项目承包',
                property: 'isSubcontract'
              }, {
                label: '合同总额（元）',
                property: 'money'
              }, {
                label: '建设单位',
                property: 'projectConstructor'
              }, {
                label: '中标日期',
                property: 'timeSuccess'
              }, {
                label: '提交时间',
                property: 'createTime'
              }, {
                label: '操作',
                property: 'handleDetails'
              }]
            } else {
              this.tableHeaderData = [{
                label: '项目名称',
                property: 'projectName'
              }, {
                label: '项目承包',
                property: 'isSubcontract'
              }, {
                label: '中标日期',
                property: 'timeSuccess'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }, {
                label: '操作',
                property: 'handleDetails'
              }]
            }
            res.list.forEach(item => {
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              if (item.partContractFileUrl && item.partContractFileUrl.indexOf(',') !== -1) {
                const a = item.partContractFileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.partContractImgUrl = imgUrl
                  item.partContractPdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.partContractFileUrl) {
                  if (item.partContractFileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.partContractFileUrl)
                  } else {
                    imgUrl.push(item.partContractFileUrl)
                  }
                }
                item.partContractImgUrl = imgUrl
                item.partContractPdfUrl = pdfUrl
              }
              if (item.invoiceFileUrl && item.invoiceFileUrl.indexOf(',') !== -1) {
                const a = item.invoiceFileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.invoiceImgUrl = imgUrl
                  item.invoicePdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.invoiceFileUrl) {
                  if (item.invoiceFileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.invoiceFileUrl)
                  } else {
                    imgUrl.push(item.invoiceFileUrl)
                  }
                }
                item.invoiceImgUrl = imgUrl
                item.invoicePdfUrl = pdfUrl
              }
              item.issueData = item.issueData ? item.issueData : '-'
              if (item.isSubcontract === '1') {
                item.isSubcontract = '总包'
              } else if (item.isSubcontract === '0') {
                item.isSubcontract = '分包'
              }
              item.timeSuccess = item.timeSuccess ? item.timeSuccess.substring(0, 10) : '-'
              arr.push(item)
            })
            console.log(arr)
            vm.tableData = arr
            // 知识产权
          } else if (vm.btnActive === 4) {
            if (vm.typeList[4].status === '15') {
              if (vm.activeName === '0') {
                this.tableHeaderData = [{
                  label: '专利名称',
                  property: 'title'
                }, {
                  label: '证书编号',
                  property: 'certificateNumber'
                }, {
                  label: '专利类别',
                  property: 'kindcodedesc'
                }, {
                  label: '发证日期',
                  property: 'releaseDate'
                }, {
                  label: '原件扫描件',
                  property: 'handleImg'
                }, {
                  label: '提交时间',
                  property: 'createTime'
                }]
              } else {
                this.tableHeaderData = [{
                  label: '专利名称',
                  property: 'title'
                }, {
                  label: '证书编号',
                  property: 'certificateNumber'
                }, {
                  label: '审批意见',
                  property: 'reason'
                }, {
                  label: '原件扫描件',
                  property: 'handleImg'
                }, {
                  label: '审批时间',
                  property: 'auditDate'
                }, {
                  label: '操作',
                  property: 'handleDetails'
                }]
              }
              res.list.forEach(item => {
                if (item.fileUrl.indexOf(',') !== -1) {
                  const a = item.fileUrl.split(',')
                  if (a.length > 0 && a[0]) {
                    const imgUrl = []
                    const pdfUrl = []
                    for (let i = 0; i < a.length; i++) {
                      if (a[i].indexOf('pdf') !== -1) {
                        pdfUrl.push(a[i])
                      } else {
                        imgUrl.push(a[i])
                      }
                    }
                    item.imgUrl = imgUrl
                    item.pdfUrl = pdfUrl
                  }
                } else {
                  const imgUrl = []
                  const pdfUrl = []
                  if (item.fileUrl) {
                    if (item.fileUrl.indexOf('pdf') !== -1) {
                      pdfUrl.push(item.fileUrl)
                    } else {
                      imgUrl.push(item.fileUrl)
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
                item.issueData = item.issueData ? item.issueData : '-'
                item.releaseDate = item.releaseDate ? item.releaseDate.substring(0, 10) : '-'
                arr.push(item)
              })
              vm.tableData = arr
            } else {
              if (vm.activeName === '0') {
                this.tableHeaderData = [{
                  label: '软著名称',
                  property: 'name'
                }, {
                  label: '登记号',
                  property: 'registerNo'
                }, {
                  label: '登记批准日期',
                  property: 'registerAperDate'
                }, {
                  label: '原件扫描件',
                  property: 'handleImg'
                }, {
                  label: '提交时间',
                  property: 'createTime'
                }, {
                  label: '操作',
                  property: 'handleDetails'
                }]
              } else {
                this.tableHeaderData = [{
                  label: '软著名称',
                  property: 'name'
                }, {
                  label: '登记号',
                  property: 'registerNo'
                }, {
                  label: '审批意见',
                  property: 'reason'
                }, {
                  label: '原件扫描件',
                  property: 'handleImg'
                }, {
                  label: '审批时间',
                  property: 'auditDate'
                }, {
                  label: '操作',
                  property: 'handleDetails'
                }]
              }
              res.list.forEach(item => {
                if (item.fileUrl.indexOf(',') !== -1) {
                  const a = item.fileUrl.split(',')
                  if (a.length > 0 && a[0]) {
                    const imgUrl = []
                    const pdfUrl = []
                    for (let i = 0; i < a.length; i++) {
                      if (a[i].indexOf('pdf') !== -1) {
                        pdfUrl.push(a[i])
                      } else {
                        imgUrl.push(a[i])
                      }
                    }
                    item.imgUrl = imgUrl
                    item.pdfUrl = pdfUrl
                  }
                } else {
                  const imgUrl = []
                  const pdfUrl = []
                  if (item.fileUrl) {
                    if (item.fileUrl.indexOf('pdf') !== -1) {
                      pdfUrl.push(item.fileUrl)
                    } else {
                      imgUrl.push(item.fileUrl)
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
                item.registerAperDate = item.registerAperDate ? item.registerAperDate.substring(0, 10) : '-'
                arr.push(item)
              })
              vm.tableData = arr
            }
            // 税务荣誉
          } else if (vm.btnActive === 5) {
            if (vm.activeName === '0') {
              this.tableHeaderData = [{
                label: '评价年度',
                property: 'year'
              }, {
                label: '纳税信用等级',
                property: 'level'
              }, {
                label: '公告链接',
                property: 'announcementUrl'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '提交时间',
                property: 'createTime'
              }]
            } else {
              this.tableHeaderData = [{
                label: '评价年度',
                property: 'year'
              }, {
                label: '纳税信用等级',
                property: 'level'
              }, {
                label: '公告链接',
                property: 'announcementUrl'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }]
            }
            res.list.forEach(item => {
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              item.issueData = item.issueData ? item.issueData : '-'
              item.releaseDate = item.releaseDate ? item.releaseDate.substring(0, 10) : '-'
              arr.push(item)
            })
            vm.tableData = arr
            // 其他
          } else if (vm.btnActive === 6) {
            if (vm.activeName === '0') {
              this.tableHeaderData = [{
                label: '资料名称',
                property: 'informationName'
              }, {
                label: '链接',
                property: 'url'
              }, {
                label: '备注',
                property: 'remark'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '提交时间',
                property: 'createTime'
              }]
            } else {
              this.tableHeaderData = [{
                label: '资料名称',
                property: 'informationName'
              }, {
                label: '链接',
                property: 'url'
              }, {
                label: '备注',
                property: 'remark'
              }, {
                label: '审批意见',
                property: 'reason'
              }, {
                label: '原件扫描件',
                property: 'handleImg'
              }, {
                label: '审批时间',
                property: 'auditDate'
              }]
            }
            res.list.forEach(item => {
              if (item.fileUrl.indexOf(',') !== -1) {
                const a = item.fileUrl.split(',')
                if (a.length > 0 && a[0]) {
                  const imgUrl = []
                  const pdfUrl = []
                  for (let i = 0; i < a.length; i++) {
                    if (a[i].indexOf('pdf') !== -1) {
                      pdfUrl.push(a[i])
                    } else {
                      imgUrl.push(a[i])
                    }
                  }
                  item.imgUrl = imgUrl
                  item.pdfUrl = pdfUrl
                }
              } else {
                const imgUrl = []
                const pdfUrl = []
                if (item.fileUrl) {
                  if (item.fileUrl.indexOf('pdf') !== -1) {
                    pdfUrl.push(item.fileUrl)
                  } else {
                    imgUrl.push(item.fileUrl)
                  }
                }
                item.imgUrl = imgUrl
                item.pdfUrl = pdfUrl
              }
              item.issueData = item.issueData ? item.issueData : '-'
              item.releaseDate = item.releaseDate ? item.releaseDate.substring(0, 10) : '-'
              arr.push(item)
            })
            vm.tableData = arr
          }
          vm.loading = false
        }
      })
      // setTimeout(function () {
      //   vm.loading = false
      // }, 1000)
    },
    // 翻页时触发
    currentChange (cuttentPage) {
      const vm = this
      vm.page.pageNum = cuttentPage
      vm.getData()
    },
    // 获取弹出框的名字
    getDialogTitle () {
      const vm = this
      if (vm.btnActive === 0) return '资质证书详情'
      if (vm.btnActive === 1) return '荣誉证书详情'
      if (vm.btnActive === 2) return '技术人员详情'
      if (vm.btnActive === 3) return '项目业绩详情'
      if (vm.btnActive === 4 && vm.typeList[4].status === '15') return '专利信息详情'
      if (vm.btnActive === 4 && vm.typeList[4].status === '16') return '软件著作权详情'
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.content-list {
  padding: 0 20px 10px 20px;
  .nav-message {
    position: absolute;
    top: 15px;
    right: 0;
    color: #999;
    font-size: 14px;
  }
  .btn-type {
    padding: 5px 10px;
    width: 90px;
    cursor: pointer;
    border: 1px solid #E6E6E6;
    color: #333;
    font-size: 14px;
  }
  .btn-type.active {
    color: #fff;
    background-color: #5D6FE9;
  }
  .headerColor {
    background-color:#E8EBFC;
    color:#666666;
    font-size:14px;
    text-align:center;
  }
}
.btn-hover-foucs {
  cursor: pointer;
  text-decoration: none;
}
.btn-hover-foucs:hover {
  font-weight: bold;
  color: #5D6FE9 !important;
}
.font-title-tabs {
  font-size: 16px;
  font-weight: blod;
}
/deep/ .el-tabs__item{
  height: 55px;
  line-height: 55px;
}
</style>
